import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"
import SEO from "../../components/seo"

import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import { graphql } from "gatsby"

import Button from "@bit/azheng.joshua-tree.button"

export default function LocationBlocks(props) {
  if (props.locations.length) {
    return props.locations.map(locationObj => {
      let location = locationObj.locationData
      return (
        <Block
          language={props.language}
          key={location.locationName}
          location={location}
          pageLink={locationObj.pageData.title}
          hasForm={locationObj.pageData.layout_3.formSection.hasForm}
          formId={locationObj.pageData.layout_3.formSection.seamlessShortCode}
          setActiveForm={props.setActiveForm}
          handleFormChange={props.handleFormChange}
        />
      )
    })
  } else {
    return <Block language={props.language} location={props.locations} />
  }
}

function Block(props) {
  {
    /* S DOS specific offices */
  }
  if (props.language === "es") {
    return (
      <div>
        <div className="contact-sidebar-block">
          {props.location.cityZip === "92120" && (
            <h5>Dirección Oficina del Este</h5>
          )}
          {props.location.cityZip === "92117" && (
            <h5>Dirección Oficina de la Costa</h5>
          )}
          <a
            href={props.location.googlePlaceLink}
            target="_blank"
            rel="noopener noreferrer">
            {props.location.streetNumber + " "}
            {props.location.streetName}
            <br />
            {props.location.cityName}
            {", " + props.location.stateAbbr + " "}
            {props.location.cityZip}
          </a>
        </div>
        <div className="contact-sidebar-block">
          <h7>{props.language === "es" ? "Teléfono" : "Phone"}</h7>
          <a href={"tel:+1" + props.location.phone.replace(/\D/g, "")}>
            {props.location.phone}
          </a>
        </div>

        <div
          className="contact-sidebar-block"
          style={{
            marginBottom:
              props.location.cityName === "Jacksonville" ? "60px" : "60px"
          }}>
          <GeneralHours
            language={props.language}
            contact={true}
            locationHours={props.location.locationHours}
          />

          <a
            className="standard-button contained"
            href={
              props.hasForm ? props.location.chatURL : `/${props.pageLink}`
            }>
            {`Contact ${props.location.locationName}`}
          </a>
        </div>
        {/* {location.cityName !== "Jacksonville" && <div className="contact-reviews">
          <SocialReviews language={language} google city={location.cityName} paddingBottom={i === sidebarLocations.length - 1 ? "32px": ""} />
        </div>} */}
      </div>
    )
  }

  return (
    <div className="location-details-item">
      <div className="contact-sidebar-block">
        <h5>
          {props.location.locationName}{" "}
          {props.language === "es" ? "Dirección" : "Address"}
        </h5>

        <a
          href={props.location.googlePlaceLink}
          target="_blank"
          rel="noopener noreferrer"
          className="street-address">
          {props.location.streetNumber + " "}
          {props.location.streetName}
          <br />
          {props.location.cityName}
          {", " + props.location.stateAbbr + " "}
          {props.location.cityZip}
        </a>
        {/* PNW long address. adding iinvisible characters to match long */}
        {/* {props.location.cityName === "Renton" ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;</> : ""} */}
      </div>
      <div className="contact-sidebar-block">
        <h7>{props.language === "es" ? "Teléfono" : "Phone"}</h7>
        <a href={"tel:+1" + props.location.phone.replace(/\D/g, "")}>
          {props.location.phone}
        </a>
      </div>

      <div className="contact-sidebar-block">
        <h7>Fax</h7>
        <span>{props.location.fax}</span>
      </div>

      <div className="contact-sidebar-block">
        <GeneralHours
          language={props.language}
          contact={true}
          locationHours={props.location.locationHours}
        />

        {!props.hasForm ? (
          ""
        ) : (
          <a
            className="standard-button contained"
            href="javascript:void(0)"
            onClick={() => props.handleFormChange(props.formId)}>
            Message
          </a>
        )}
      </div>
      {/* {location.cityName !== "Jacksonville" && <div className="contact-reviews">
      <SocialReviews language={language} google city={location.cityName} paddingBottom={i === sidebarLocations.length - 1 ? "32px": ""} />
    </div>} */}
    </div>
  )
}
